<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<Message v-if="loading" message="loading" type="info" />

		<template v-else>
			<Card
				v-for="client of clients"
				:key="client.id"
			>
				<template #header>
					{{ client.client_id }}
				</template>

				<template #default>
					<ul>
						<li v-for="uri of client.redirect_uris" :key="uri">
							{{ uri }}
						</li>
					</ul>
				</template>

				<template v-if="!client.static" #footer>
					<Button
						class="card-button"
						@click="() => edit(client.client_id)"
					>
						Edit
					</Button>

					<Button
						class="card-button delete"
						@click="() => deleteClient(client.client_id)"
					>
						Delete
					</Button>
				</template>
			</Card>

			<div class="navigation">
				<Button @click="back">
					Back
				</Button>

				<Button @click="create">
					Create
				</Button>
			</div>
		</template>
	</div>
</template>

<script>
import api from "@/api";

import Card 		from "@/components/Card.vue";
import Button		from "@/components/Button.vue";
import Message		from "@/components/Message.vue";

export default {
	name: "Clients",

	components: {
		Card,
		Button,
		Message
	},

	data () {
		return {
			error: undefined,
			loading: true,

			clients: []
		};
	},

	async beforeMount () {
		await this.update();
	},

	methods: {
		async update () {
			this.error = undefined;

			try {
				this.clients = await api.getClients();
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			this.loading = false;
		},

		edit (id) {
			this.$router.push({
				name: "Client",
				params: { id }
			});
		},

		create () {
			this.$router.push({
				name: "Client"
			});
		},

		async deleteClient (id) {
			try {
				this.loading = true;
				await api.deleteClient(id);
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			await this.update();
		},

		back () {
			this.$router.push({
				name: "Admin"
			});
		}
	}
};
</script>

<style scoped>
.card-button {
	width: 38%;
	margin-left: auto;
	margin-right: auto;
}

.delete {
	color: white;
	background-color: #f66;
}

.delete:hover {
	color: white;
	background-color: #ff8080 !important;
}

.navigation {
	display: flex;
}

.navigation > * {
	width: 40%;
	margin: auto;
}
</style>
